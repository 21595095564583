import React, { PureComponent, useState, useCallback, useEffect } from "react";
// import CountDownBlock from "./CountDownBlock";

import Link from "./LinkWithTransitionLock"
import Helmet from "react-helmet"
import InViewObserver from "./InViewObserver"

import { Expo, gsap } from 'gsap';

class Page extends PureComponent {
  constructor(props) {
     super(props);
  }
  
  componentDidMount() {

    InViewObserver()

  }

  componentWillUnmount() {}

  render() {

    var next_visual = require('../images/home/project_c.png');

    return (
      <div className="content_wrap ">

        <div className="page_header">
          <h2 className="sub_title">策展人/方彥翔</h2>
          <h1 className="main_title">一個論述的提案 (下)</h1>
          {/* <h6 className="speaker">策展人/方彥翔</h6> */}
        </div>

        <div className="page_content project_b">

          <section className="row_area_s project_b_a">
            <div className="column_5">
              <div className="context">
                <h4>Single-Person Tribe</h4>
                <p>我們並不容易去想像巴特那種修道院式的共同生活，以近乎公社般在公共社宅社區的居住型態中實踐，但我們可以想像田中功起式的召集帶著畫布上街的無訴求遊行，或者香港藝術家白雙全及計畫中號召人們在城市中走一條地圖上的直線（《谷之旅》，2017 - ），這樣臨時社群如何在當代生活中閃現、發揮作用。事實上，臨時社群並不擔保著社區的緊密性，而借助臨時社群的行動雖帶有一種「剎那烏托邦」的想像，但它也恰恰並非在完成此烏托邦，而是透過每每只能夠的暫存力量，廢止了烏托邦而讓我們回到共活的現實/真實本身。</p>
              </div>
            </div>
          </section>

          <section className="row_area_s project_b_b">
            <div className="column_6">
              <div className="context">
                <h4>親密性、家政事與照護時間</h4>
                <p>當代公共藝術所強調的「公眾參與」、「政治參與」是建立於「它者性」的認知與思考，這成為公共建制可能的基礎。這一點在韓炳哲（Byung-Chul Han）所謂「它者的消失」（The Expulsion of the Other: Society, Perception and Communication Today, 2018） 或理查．桑內特（Richard Sennett)「再會吧！公共人」（The Fall of Public Man, 1977），當代社會的公領域和生活場域與兩者之間都面對著「同質化」的威脅。</p>
                <p>對韓炳哲而言，當代媒介社會是一個將私密展演化的社會，這種私密性（或稱親密性）的展示化是去聖化、商品化與曝露狂的，是高資本邏輯與奇觀社會的更深一層擴張。而桑內特強調的那種劇場性雄辯式的公共性政治身體正在褪型，取而代之的是在公共建築空間中召喚個人性的身體的相互溶接，這攸關於身體媒介經驗與行動化媒介化，以及病理化社會擴張、同化的傾向，也都意味著私密性本身的消失——伴著當代民主公領域所需維繫建立在的「匿名性」一同消失。</p>
                <p>在大流行病時代的今天，一種可以維持「社會距離」（social distancing）的公共性需求出現了，身體距離與親密準則被重新設定，假想一種如同遠距分享、線上瀏覽那種專屬於個人的公共藝術作品，它會是什麼樣貌，而又如何來實現？然而，似乎又透過物聯網的這種物件與身體既遠距又連結的關係，保留了這個親密性的所在。</p>
              </div>
            </div>
          </section>

          <section className="row_area_s project_b_b">
            <div className="column_5">
              <div className="context">
                <p>在吉麗安娜‧布魯諾（Giuliana Bruno）所稱「公共私密性」（public intimacy）的討論，其建立在以影像性經驗出發的空間性生產與空間經驗變異；涉及空間建築與身體圖式的連結關係（路易•布爾喬亞(Louise Bourgeois)為例）、「時間建築」（architectonics of time ）裡個體記憶在影像性空間中的「漫溢」（蔡明亮為例）、身體書寫與潛意識的居住化（雷貝嘉•霍恩(Rebecca Horn)為例）（吉麗安娜‧布魯諾，2007）。我們可以在台灣藝術家何采柔《某日》、《在第二天，星期六，你的3分鐘……》等作品中看見這樣的傾向，以及在「公共私密性」深一層的操作。在作品中半透明、半孤立的介面-空間狀態，不完全的溝通情境與納西斯式自我的親密——畢竟親密是一種關係需要兩個人一同進行完成——體現了公共與私密非辨證性的模糊化；何采柔作品表面上彰顯的是當代這種空白、片刻抹去、一再佚失又重複喚醒的記憶特質之神經質主體性，然而我們在她作品再度賦予日常生活儀式性的細微手勢，投射某種感染力的來對差異空間共同化，可以發現藝術家在當代社會這種失去親密性又廣佈親密感的處境中，試圖在重新設置的時間感中，進行某種共感的、心理性的縫合。</p>
                <p>讓我們將場景移向生活中一個長時間被獨處佔據的範圍，另一個身體政治的爭鬥空間：家居空間與家政事展演。就家居的空間政治而言，支撐居住生活需要透過大量的家事勞動，而家事勞動和主理者亦佔據了家居空間最大多數者，然而當代生活政治的權力關係中我們始終將家政事範圍化，將之放置於生活的後台，家政空間成為一種附屬性的空間，目光焦點與期待總是在美好家庭景象，精美秀色的餐食、有條不紊的收納，而非主角（通常總是女性）。這在1960年代以來，批判父權藝術史傳統上那塑造賢淑、展現文化教化的女性形象，例如瑪莎‧羅斯勒（Martha Rosler）等藝術家可以看到，挑戰社會規範與認知，將家庭私人空間的觀點帶入公共領域，揭露不平衡的勞動生活與社會條件處境、權力結構。</p>
                <p>韓國藝術家朴寶娜（Bona Park）作品《家事規模的編舞II》（Domestic-scale Choreography 2）是邀請Delfina基金會駐地空間的已經工作6年的清潔人員菲洛梅娜‧洛佩斯（Filomena Lopes），進行將其家事清潔工作的日常勞動表演，將剛洗完沒有擰乾衣服的水滴擦拭，一邊唱歌與跳舞。紐約藝術家雅典娜‧柯可羅妮思（Athena Kokoronis）的《家政表演事務所》是一個計畫性空間（Domestic-Performance Agency），將日常家政的家事、烹煮、裁縫 轉譯為表演性行動或舞蹈，並將家政事延伸至社會經濟層面的討論。這些項目，不僅僅是去展演化女性與家事勞動者的工作狀態、精神狀態，呈現被看見與改善的需求，更並非是去劇場化家庭中典範圖象和被掩蓋的抑鬱與暴力。這種日常中持續性發生的家居勞動與在其中進行的擾動，透過藝術與日常生活之間的界限混亂、顛覆、拆解、重新包覆，轉化為一個自我對話空間，以及自我照護的時刻，反而轉換個體與社會結構的關係，成為新的社會和環境背景。</p>
              </div>
            </div>
          </section>

          <section className="row_area_s project_b_c row_break">
            <div className="column_6 onset_left_1">
              <div className="context">
                <h4>除了免疫學，我們還有什麼群體技術？</h4>
                <p>在今日全球大流行的緊急情況下重新思考社區與公共生活，一定程度我們回到過去免疫學式社會：在羅伯托·埃斯波西托（Roberto Esposito）的生命政治哲學概念裡，他考掘拉丁文字源，「communitas」（共同體）與「immunitas」（免疫）共有的字根「munus」原來的意思為「禮物」，並且帶著義務與責任的意函而非權力與財產。「共同體」（社群）代表著共同擁有義務與責任，而「免疫」的原義則以相義的字首表示著「在法律上豁免了義務與責任」，直到18世紀後生物學與疾病研究才賦予了「immunity」具有「免疫」的意義。在埃斯波西托意義中的「共同體」是將自己與別人的未來肯定化，建立在一種生命與自由發展的關係，一種共同化的生命狀態，並且是面對自己的義務，而免疫學社會中的生命政治則是恰恰與此相反，而透過對他者的否定與抵禦成立。（埃斯波西托，2010: 1-20）</p>
                <p>社區如何維持開放並且連結出更多交流，會是當代都市規劃與實踐的新課題，而非傳統上的保護、封閉式照護——特別是今天監控、公共安全系統、身體照護甚至情感慰藉的自動化。在台北市社宅之整體規劃中，社宅選址在土地所有權、徵收與地價等因素影響下，多為座落於城市的邊垂區域，社宅強調對外的連結性勝於內部的功能與設施的完整性：以萬華青年社宅為例，青年公園與馬場町公園皆成為其廣大的腹地（相比與其社區內部公共空間），事實上我們有目睹社區內外連結的關係與動態，具備著表面上看不到的活力與靈活度。社區生活並非緊鄰生活在一起、共享一定程度財產的公社生活，它是以自由主義社會的流動方式運作形成的生活節奏。在家庭單位的生活之外，連結可能是透過一系列的節目參與發生——這也是包含台北市社宅所運作的青創計畫和社區性公共藝術計畫，所致力在創造各種分享、自學、連結與交流的契機——以及社區的共同生活環境中，各種連結會在生活機能上發生，在食衣住行與資訊情報等日常需求上，因彼此溝通與交換行為，而自然社區內部會有機地形成互助照護及共享的網絡。這也是為何今天社區型（藝術）實踐被賦予更多想像上的任務，將生活場域虛構虛設出更多樣的情境，重新去構想連結的關係路徑，不僅僅社區內部、不同社區群體之間，當然包括不同個體間，或自我生命某個片刻到下一段生命歷程的經驗對話。</p>
                <p>在理查．桑内特（Richard Sennett）討論「開放城市」（open city）時，以「複雜性」將都市學與政治學聯繫起來，定義其從社會的角度是對話式的（dialogical）、從經濟的角度是同步的（synchronous）、從政治的角度相（對於所屬的民族國家）偏向左翼的理想社會（江子卉，2020）。社會住宅本身是城市基本居住權與社會照護價值的體現，它具備某種永續性目標過程中的暫時性功能；而如果社宅公共藝術實踐是發展某種生活技術，它需要被期待為一種創造複雜性的技術，也是一再更新拓樸關係的技術，推動「由下而上推進的社會賦權」的「共生式轉型」。</p>
                <p>如桑內特所言：「在一個開放城市中，人們能夠更熟練地處理生活中的複雜情況，並且能夠利用意料之外的機會。」(見江子卉，2020）而社宅公共藝術則可以是一再誕生複雜情況，並且引領我們面向「意料之外」不可或缺的角色。</p>
              </div>
            </div>
          </section>

        </div>


        <div className="page_footer">
          <Link to="/project_c" className="next_page">
            <div className="next_title">駐村編輯</div>
            <div className="next_owner">策展人/林書豪</div>
            <div className="next_visual" style={ { backgroundImage: "url(" + next_visual + ")" } }></div>
          </Link>
        </div>

      </div>


      // <CountDownBlock blockType="countDown" targetDate={new Date(2020, 3, 27, 22)}>
      //   <h4>《單人部落》</h4>
      //   <h5>一人成為聚落如何可能</h5>
      //   <h6>方彥翔/高雄市立美術館 助理策展人</h6>
      // </CountDownBlock>
    );
  }
}


export default Page
